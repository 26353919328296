// extracted by mini-css-extract-plugin
export var administrativeDivisions = "LocalityIndex__administrativeDivisions__VMUI5";
export var badge = "LocalityIndex__badge__IBSlh";
export var column = "LocalityIndex__column__yHEdT";
export var controlCell = "LocalityIndex__controlCell__M5vIS";
export var controlRightAlign = "LocalityIndex__controlRightAlign__3BAvf";
export var fetchError = "LocalityIndex__fetchError__nIaHd";
export var header = "LocalityIndex__header__HqU0A";
export var headerControls = "LocalityIndex__headerControls__Oj_M3";
export var layout = "LocalityIndex__layout__n7VXi";
export var pageContainer = "LocalityIndex__pageContainer__daAH_";
export var pagination = "LocalityIndex__pagination__LLKO1";
export var row = "LocalityIndex__row__xAldu";
export var searchField = "LocalityIndex__searchField__kIHqX";
export var selected = "LocalityIndex__selected__McF72";
export var spinner = "LocalityIndex__spinner__x7GIx";
export var subHeader = "LocalityIndex__subHeader__dJ7tJ";
export var subTitle = "LocalityIndex__subTitle__CZb9s";
export var table = "LocalityIndex__table__ByL_W";
export var tableLink = "LocalityIndex__tableLink__ZkVod";
export var title = "LocalityIndex__title__TJhFu";